
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinDocument from '@/mixins/mixinDocument';
import CommonProgress from "@/components/common/Progress.vue";
import ContractdocumentMain from "@/components/contractdocument/Main.vue";

@Component({
  components: {
    CommonProgress,
    ContractdocumentMain,
  }
})
export default class Config extends Mixins(mixinDocument) {
}
