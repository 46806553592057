
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import CommonProgress from "@/components/common/Progress.vue";
import ContractdocumentDisplay from "@/components/contractdocument/Display.vue";

@Component({
  components: {
    CommonProgress,
    ContractdocumentDisplay,
  }
})
export default class Main extends Mixins(mixinDocument) {
}
