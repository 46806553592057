
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinDocument from '@/mixins/mixinDocument';
import PDFJS from '@/js/pdfjs/pdf';
import CommonProgress from "@/components/common/Progress.vue";
import CommonText from "@/components/common/Text.vue";
import CommonBigbutton from "@/components/common/Bigbutton.vue";

@Component({
  components: {
    CommonProgress,
    CommonText,
    CommonBigbutton,
  }
})
export default class Main extends Mixins(mixinDocument) {
  created() {
    this.m.setContract(null);
  }
  
  get source_file() {
    return this.contract_document.pdf;
  }

  get tmp_contract() {
    return this.m.contract;
  }
  set tmp_contract(val) {
    this.m.setContract(val);
  }

  admit_contract_document() {
    this.m.admitContractDocument({contract_document_id: this.contract_document_id});
  }
  deny_contract_document() {
    this.m.denyContractDocument({contract_document_id: this.contract_document_id});
  }

  get is_app() {
    const app_token = util.getLocalStorage('app_token');
    return app_token == null ? 0 : 1;
  }
}


